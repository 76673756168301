import React, { ChangeEvent, FunctionComponent, ReactText, useEffect, useState } from 'react';
import { Button, Dropdown, FlagIcon, Icon, PaymentIcon, SocialIcon, Switch } from 'rs-emd-ui-atoms';
import { useUser } from '../../../../components/app/user-context';
import { getAttributes, getCookie, getLabel, getTagAttributes, setCookie } from '../../helpers/html.utils';
import { getSiteCurrencyId, setSiteCurrency, setSiteLanguage } from '../../helpers/site-settings.utils';
import { getLanguageCookie, includeVat, setVatCookie } from '../../helpers/user-settings.utils';
import { IFooter } from '../../models/master/footer/footer.model';
import { ISiteSettingsCountry } from '../../models/master/site-settings-country.model';
import { ISiteSettingsLanguage } from '../../models/master/site-settings-language.model';
import styles from './footer-component.module.scss';

interface FooterProps {
  data: IFooter;
  isCheckout?: boolean;
  pushGA4Event: (event: any) => void;
}

export const Footer: FunctionComponent<FooterProps> = (props: FooterProps) => {
  const countryCookie = 'UserCountry';

  const { customer } = useUser();
  const [defaultLang, setDefaultLang] = useState<string | undefined>(undefined);
  const [defaultCurrency, setDefaultCurrency] = useState<number | undefined>(undefined);
  const [defaultCountry, setDefaultCountry] = useState<string | undefined>(undefined);
  const [defaultVATOption, setDefaultVATOption] = useState<boolean>(false);
  const showSiteSettings =
    props.data.siteSettings.showLanguages ||
    props.data.siteSettings.showCurrencies ||
    props.data.siteSettings.showCountries ||
    props.data.siteSettings.showVATToggle;

  useEffect(() => {
    // Default Lang
    if (props.data.siteSettings.showLanguages) {
      const selectedLangId = getLanguageCookie();
      let selectedLang: ISiteSettingsLanguage | undefined = undefined;

      //check if selected language exists in props
      if (selectedLangId) {
        selectedLang = props.data.siteSettings.languages.find((l) => l.baseDirectory === selectedLangId);
      } else {
        selectedLang = props.data.siteSettings.languages.find((l) => l.isSelected);
      }
      setDefaultLang(selectedLang ? selectedLang.countryCodeISO2 : props.data.siteSettings.languages[0].countryCodeISO2);
    }

    if (props.data.siteSettings.showCurrencies) {
      setDefaultCurrency(getSiteCurrencyId(props.data.siteSettings.currencies));
    }

    if (props.data.siteSettings.showCountries) {
      // Default currency
      const selectedCountryId = getCookie(countryCookie);
      let selectedCountry: ISiteSettingsCountry | undefined = undefined;

      //check if selected language exists in props
      if (selectedCountryId) {
        selectedCountry = props.data.siteSettings.countries.find((l) => l.id === selectedCountryId);
      } else {
        selectedCountry = props.data.siteSettings.countries.find((l) => l.isSelected);
      }
      setDefaultCountry(selectedCountry ? selectedCountry.id : undefined);
    }

    setDefaultVATOption(includeVat());
  }, [
    props.data.siteSettings.countries,
    props.data.siteSettings.currencies,
    props.data.siteSettings.languages,
    props.data.siteSettings.showCountries,
    props.data.siteSettings.showCurrencies,
    props.data.siteSettings.showLanguages,
  ]);

  return (
    <footer className={`${styles['footer-container']} ${props.isCheckout ? styles['footer-container-checkout'] : ''}`}>
      <div className={styles['top-sections']}>
        <div className={`page-content ${styles['top-sections-inner']}`}>
          {/* Payment Section */}
          {props.data.config?.paymentMethods && props.data.config?.paymentMethods.length > 0 && (
            <div className={`${styles.section} ${styles.payments}`}>
              <span className={styles['section-heading']}>{getLabel(props.data.labels, 'payment_title')}</span>

              {/* Payment Options */}
              <div className={styles.options}>
                <div className={styles.methods}>
                  {props.data.config?.paymentMethods &&
                    props.data.config?.paymentMethods.map((pm) => {
                      return <PaymentIcon name={pm} className={styles[pm]} key={`footer_pm_${pm}`} /> ?? <></>;
                    })}
                </div>
                <span className={styles[`bank-transfer`]}>{getLabel(props.data.labels, 'payment_content')}</span>
              </div>
            </div>
          )}

          {/* Site Settings Section */}
          {showSiteSettings && (
            <div
              className={`${styles.section} ${styles.settings} ${
                props.data.config?.paymentMethods && props.data.config?.paymentMethods.length > 0 ? styles['after-payment'] : ''
              }`}>
              <span className={styles['section-heading']}>{getLabel(props.data.labels, 'site_settings')}</span>
              <div className={styles.options}>
                {props.data.siteSettings.showLanguages && (
                  <div className={styles['lang-container']}>
                    <Dropdown
                      className={`${styles.dropdown} ${styles.languages}`}
                      label={getLabel(props.data.labels, 'language')}
                      defaultSelection={defaultLang}
                      selectCallback={(key) => setSiteLanguage(props.data.siteSettings.languages, key)}
                      list={props.data.siteSettings.languages.map((l) => {
                        return {
                          key: l.countryCodeISO2,
                          elt: <span>{l.name}</span>,
                          searchableString: l.name,
                        };
                      })}></Dropdown>
                  </div>
                )}

                {props.data.siteSettings.showCountries && (
                  <div className={styles['country-container']}>
                    <Dropdown
                      className={`${styles.dropdown} ${styles.countries}`}
                      label={getLabel(props.data.labels, 'country')}
                      defaultSelection={defaultCountry}
                      placeholder={getLabel(props.data.labels, 'please_select')}
                      selectCallback={setCountry}
                      list={props.data.siteSettings.countries.map((c) => {
                        return {
                          key: c.id,
                          elt: (
                            <div className={styles['country-opt']}>
                              <FlagIcon name={c.codeISO2.toLowerCase()} />
                              <span>{c.name}</span>
                            </div>
                          ),
                          searchableString: c.name,
                        };
                      })}></Dropdown>
                  </div>
                )}

                <div className={styles['other-opts-container']}>
                  {props.data.siteSettings.showCurrencies && (
                    <Dropdown
                      className={`${styles.dropdown} ${styles.currencies}`}
                      label={getLabel(props.data.labels, 'prices')}
                      defaultSelection={defaultCurrency}
                      selectCallback={(key) => setSiteCurrency(props.data.siteSettings.currencies, key)}
                      list={props.data.siteSettings.currencies.map((c) => {
                        return {
                          key: c.id,
                          elt: <span>{c.displayName}</span>,
                          searchableString: c.displayName,
                        };
                      })}></Dropdown>
                  )}

                  {/* VAT */}
                  {props.data.siteSettings.showVATToggle && (
                    <div className={styles.vat}>
                      <p>{getLabel(props.data.labels, 'ex_vat')}</p>
                      <Switch id='switch-vat' checkedValue={defaultVATOption} onChange={(e) => setVat(e)}></Switch>
                      <p>{getLabel(props.data.labels, 'inc_vat')}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {props.data.config?.partnerLinks && props.data.config?.partnerLinks.length > 0 && (
            <div
              className={`${styles.section} ${styles.settings} ${
                (props.data.config?.paymentMethods && props.data.config?.paymentMethods.length > 0) || showSiteSettings
                  ? styles['after-payment']
                  : ''
              }`}>
              {props.data.config?.partnerLinks.map((p) => {
                return (
                  <a href={p.url} {...getAttributes(p.attributes)}>
                    {p.attributes?.findIndex((a) => a.name === 'data-logo') !== -1 && (
                      <img
                        src={p.attributes?.find((a) => a.name === 'data-logo')?.value}
                        alt={p.text}
                        title={p.text}
                        width={103}
                        height={103}
                      />
                    )}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Contact Us Section */}
      {(props.data.siteSettings.countries.length > 0 ? defaultCountry !== undefined : true) && (
        <div className={` ${styles['contact-us']}`}>
          <div className={`page-content ${styles.section} ${styles['contact-us-inner']}`}>
            <span className={styles['section-heading']}>{getLabel(props.data.labels, 'contact_header')}</span>

            {!props.data.siteSettings.showCountries || defaultCountry ? (
              <div className={styles.details}>
                {/* Phone */}
                <div className={styles.phone}>
                  <div
                    className={styles['contact-label']}
                    dangerouslySetInnerHTML={{ __html: getLabel(props.data.labels, 'contact_phoneintro') }}></div>
                  <a
                    className={styles['phone-button']}
                    href={`tel: ${defaultCountry ? getCountry()?.contactPhone : props.data.config?.phoneNumber}`}>
                    <Button
                      buttonType='primary'
                      size='full-width'
                      text={getLabel(props.data.labels, 'contact_callcustomerservice')}
                      icon='phone'
                      onClick={() => onContactUsClicked(true)}></Button>
                  </a>

                  <a
                    className={`${styles.link} ${styles['phone-link']}`}
                    href={`tel: ${defaultCountry ? getCountry()?.contactPhone : props.data.config?.phoneNumber}`}
                    onClick={() => onContactUsClicked(true)}>
                    <Icon name='phone' color='white' width={24} height={24}></Icon>
                    <span>{defaultCountry ? getCountry()?.contactPhone : props.data.config?.phoneNumber}</span>
                  </a>
                </div>

                {/* Email */}
                <div className={styles.email}>
                  <div
                    className={styles['contact-label']}
                    dangerouslySetInnerHTML={{ __html: getLabel(props.data.labels, 'contact_emailintro') }}></div>
                  <a
                    className={`${styles.link} ${styles['email-link']}`}
                    href={`mailto:${defaultCountry ? getCountry()?.contactEmail : props.data.config?.emailAddress}`}
                    onClick={() => onContactUsClicked(false)}>
                    <Icon name='envelope' color='white' width={24} height={24}></Icon>
                    <span>{defaultCountry ? getCountry()?.contactEmail : props.data.config?.emailAddress}</span>
                  </a>
                </div>

                {/* socials */}
                {props.data.config?.socialPlatforms && props.data.config?.socialPlatforms.length > 0 && (
                  <div className={styles.socials}>
                    <div className={styles['contact-label']}>
                      <p>{getLabel(props.data.labels, 'social_header')}</p>
                    </div>
                    <div className={styles['social-networks']}>
                      {props.data.config?.socialPlatforms &&
                        props.data.config?.socialPlatforms.map((sp) => {
                          return (
                            <SocialIcon
                              href={sp.url}
                              name={sp.text}
                              title={getLabel(props.data.labels, sp.text)}
                              svgClassName={styles[sp.text]}
                              key={`footer_sp_${sp.text}`}
                              {...getAttributes(sp.attributes)}
                            />
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles['select-country']}>
                <p className={styles.icons}>
                  <Icon name='phone' />
                  <Icon name='envelope' />
                </p>
                <p>{getLabel(props.data.labels, 'select_country_region')}</p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles['bottom-sections']}>
        <div className={`${styles.padding} ${styles.left}`} aria-hidden='true'></div>
        {/* Helpful Links Section */}
        <div className={`${styles.section} ${styles['helpful-links']}`}>
          <span className={styles['section-heading']}>{getLabel(props.data.labels, 'links_header')}</span>

          <div className={styles['column-links']}>
            {props.data.columnLinks &&
              props.data.columnLinks.map((column) => {
                return (
                  <div className={styles.column} key={`footer_cl_${column.title}`}>
                    <span className={styles['column-heading']}>{column.title}</span>
                    {column.links &&
                      column.links.map((link) => {
                        return (
                          <a key={link.text} href={link.url} {...getAttributes(link.attributes)}>
                            {link.text}
                          </a>
                        );
                      })}
                  </div>
                );
              })}
          </div>

          <div className={styles['bottom-links']}>
            {props.data.bottomLinks &&
              props.data.bottomLinks.links.map((link) => {
                return (
                  <a key={link.text} href={link.url} {...getAttributes(link.attributes)}>
                    {link.text}
                  </a>
                );
              })}
          </div>
        </div>

        {/* Copyright Section */}
        <div className={`${styles.section} ${styles['copyright']}`}>
          <span className={styles['copyright-heading']}>{getLabel(props.data.labels, 'copyright')}</span>
          <address
            dangerouslySetInnerHTML={{
              __html: (props.data.siteSettings.showCountries ? getCountry()?.contactAddress : props.data.config?.businessAddress) ?? '',
            }}></address>
          <div className={styles['developed-by']}>{getLabel(props.data.labels, 'developed_by')}</div>
        </div>
        <div className={`${styles.padding} ${styles.right}`} aria-hidden='true'></div>
      </div>

      {props.data.metaData?.structuredData?.map((sd) => {
        if (sd.innerHtml) return <script {...getTagAttributes(sd.attributes)} dangerouslySetInnerHTML={{ __html: sd.innerHtml }}></script>;
      })}
    </footer>
  );

  function setCountry(key: ReactText) {
    let currency = props.data.siteSettings.countries.find((c) => c.id === key);

    setCookie(countryCookie, currency?.id.toString() ?? '');
    setDefaultCountry(currency?.id);
  }

  function getCountry() {
    if (defaultCountry) return props.data.siteSettings.countries.find((c) => c.id === defaultCountry);
  }

  function setVat(e: ChangeEvent<HTMLInputElement>) {
    var checked = e.target.checked;
    setVatCookie(checked);
  }

  function onContactUsClicked(isPhone: boolean): void {
    const w = window as any;
    const tag = {
      event: isPhone ? 'contact_us_phone' : 'contact_us_email',
      ecommerce: {
        // hacky solution to get the pagename without an extra call to the bff
        page_name: w.rs?.web?.digitalData?.page_name ?? window.document.title,
        page_url: window.location.href,
        email: customer?.email,
      },
    };

    props.pushGA4Event(tag);
  }
};
