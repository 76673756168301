import React, { FunctionComponent } from 'react';
import {
  OrderConfirmationPageContactDetails,
  OrderDetailsPage_BillingAddress,
  OrderDetailsPage_DeliveryAddress,
  OrderDetailsPage_PaymentOption,
} from '../../../../../../../../generated/graphql';
import { getLabel } from '../../../../../../helpers/html.utils';
import { ILabel } from '../../../../../../models/common/label.model';
import styles from './delivery-details-component.module.scss';

export type DeliveryDetailsProps = {
  labels?: ILabel[];
  orderNum: string;
  contactDetails: OrderConfirmationPageContactDetails;
  deliveryAddress: OrderDetailsPage_DeliveryAddress;
  invoiceAddress?: OrderDetailsPage_BillingAddress | null;
  paymentMethod?: OrderDetailsPage_PaymentOption | null;
};

export const DeliveryDetails: FunctionComponent<DeliveryDetailsProps> = (props: DeliveryDetailsProps) => {
  function getInvoiceField(label: string, value: any) {
    return (
      <div className={styles['invoice-field']}>
        <span className={styles.bold}>{label}:</span>
        <span>{value}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles['delivery-details-container']}>
        <div className={styles['address-container']}>
          <span className={styles.title}>{getLabel(props.labels, 'delivery_address')}</span>
          <div className={styles['address-wrapper']}>
            <div className={styles['contact-details']}>
              <span className={styles.bold}>
                {props.contactDetails.firstName} {props.contactDetails.lastName}
              </span>
              <span>{props.contactDetails.email}</span>
              <span>{props.contactDetails.phoneNo}</span>
            </div>
            {props.deliveryAddress.formattedMultiLine.map((l) => (
              <p>{l}</p>
            ))}
          </div>
        </div>
        <div className={styles['address-container']}>
          <span className={styles.title}>{getLabel(props.labels, 'invoice_address')}</span>
          {props.invoiceAddress &&
            (props.deliveryAddress.uniqueId === props.invoiceAddress.uniqueId ? (
              <span>{getLabel(props.labels, 'same_as_delivery_address')}</span>
            ) : (
              props.invoiceAddress.formattedMultiLine.map((l) => <p>{l}</p>)
            ))}
          {getInvoiceField(getLabel(props.labels, 'order_no_full'), props.orderNum)}
          {getInvoiceField(getLabel(props.labels, 'payment_method'), props.paymentMethod?.description)}
          {/* {props.invoiceAddress?.details..indiaFields && (
            <div className={styles['india-fields']}>
              {getInvoiceField(getLabel(props.labels, 'purchasing_for'), props.data.invoiceArea.indiaFields.purchasingFor)}
              {getInvoiceField(getLabel(props.labels, 'gst_type'), props.data.invoiceArea.indiaFields.gstType)}
              {getInvoiceField(getLabel(props.labels, 'gst_reg_type'), props.data.invoiceArea.indiaFields.gstRegistrationType)}
              {getInvoiceField(getLabel(props.labels, 'gst_reg_num'), props.data.invoiceArea.indiaFields.gstRegistrationNumber)}
              {getInvoiceField(getLabel(props.labels, 'pan_card_num'), props.data.invoiceArea.indiaFields.panCardNumber)}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
