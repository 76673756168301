import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useForgotPasswordPageQuery } from '../../generated/graphql';
import { ForgotPasswordPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { customerService } from '../../services/customer-service';
import { useUser } from '../app/user-context';

type ForgotPasswordWrapperProps = {
  enableMyAccount: boolean;
};

export const ForgotPasswordWrapper: FunctionComponent<ForgotPasswordWrapperProps> = (props: ForgotPasswordWrapperProps) => {
  const { pathname } = useLocation();
  const user = useUser();
  const redirectToMyAccount = props.enableMyAccount && pathname.toLowerCase().endsWith('/user/forgotpassword') && user.customer.isLoggedIn;

  const { loading, error, data } = useForgotPasswordPageQuery({
    variables: {},
    ssr: true,
    skip: redirectToMyAccount,
  });

  if (redirectToMyAccount) return <RedirectWithStatus to={'/user/myaccount/resetPassword'} statusCode={302} />;
  else if (loading) {
    return <Loader />;
  } else if (error || !data || !data.forgotPasswordPage.isSuccess /** if user is already logged in */) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }

  return <ForgotPasswordPage data={data.forgotPasswordPage} customerService={customerService} />;
};

export default ForgotPasswordWrapper;
