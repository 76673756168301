import { useAuth0 } from '@auth0/auth0-react';
import React, { ChangeEvent, FunctionComponent, MouseEvent, useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Button, Card, FlagIcon, Icon, Modal, Switch, isMobileDevice } from 'rs-emd-ui-atoms';
import { BehaviorSubject } from 'rxjs';
import { getAttributes, getLabel, getTagAttributes } from '../../..';
import { PartsListContext } from '../../../../../components/app/parts-list-context';
import { useUser, useUserDispatch } from '../../../../../components/app/user-context';
import { headerService } from '../../../../../services/header-service';
import { getSiteCurrencyId, setSiteCurrency, setSiteLanguage } from '../../../helpers/site-settings.utils';
import { getLanguageCookie, includeVat, setVatCookie } from '../../../helpers/user-settings.utils';
import { IContextInfo, Locale } from '../../../models/master/context-info.model';
import { IMasterData } from '../../../models/master/master.model';
import { ISiteSettingsLanguage } from '../../../models/master/site-settings-language.model';
import { IPartsListService } from '../../../services/parts-list/parts-list-service';
import { IPredictiveSearchService } from '../../../services/predictive-search/predictive-search-service';
import { IProductService } from '../../../services/product/product-service';
import { SearchField } from '../../shared/search-field/search-field-component';
import { LoginToast } from '../../user';
import { ReactComponent as Logo } from './../../../images/rs-logo.svg';
import styles from './header-menu-component.module.scss';

type HeaderMenuProps = {
  contextInfo: IContextInfo;
  data: IMasterData;
  isCheckout?: boolean;
  menuSubject?: BehaviorSubject<boolean>;
  predictiveSearchService: IPredictiveSearchService;
  partsListService: IPartsListService;
  productService?: IProductService;
  toggleSearch: (force?: boolean) => void;
  enableMyAccount: boolean;
};

type State = {
  isMenuOpen: boolean;
  animateBasketTotal: boolean;
  isLanguageDropdownOpen: boolean;
  isCurrencyDropdownOpen: boolean;
  defaultLang: string;
  defaultCurrencyId: number;
  showCustomerLoggedInToast?: boolean;
};

type Action =
  | { type: 'toggleMenu'; isOpen: boolean }
  | { type: 'toggleLanguageDropdown'; isOpen: boolean }
  | { type: 'toggleCurrencyDropdown'; isOpen: boolean }
  | { type: 'setDefaultLang'; data: string }
  | { type: 'setDefaultCurrencyId'; data: number }
  | { type: 'animateBasketTotal' };

export const HeaderMenu: FunctionComponent<HeaderMenuProps> = (props: HeaderMenuProps) => {
  const oktaAuthEnabled = process.env.REACT_APP_AUTH_ENABLED === 'true';
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const userDispatch = useUserDispatch();
  const currentUser = useUser();
  const history = useHistory();

  const { setPartsListProps } = useContext(PartsListContext);

  const [state, dispatch] = useReducer(reducer, {
    isMenuOpen: false,
    isLanguageDropdownOpen: false,
    isCurrencyDropdownOpen: false,
    animateBasketTotal: false,
    defaultLang: '',
    defaultCurrencyId: 0,
  });

  const [isVATSelected, setIsVATSelected] = useState<boolean>(false);

  useLayoutEffect(() => {
    props.menuSubject?.subscribe((x) => dispatch({ type: 'toggleMenu', isOpen: x }));

    return () => {
      // Cleanup & unsubscribe
      props.menuSubject?.unsubscribe();
    };
  }, [props.menuSubject]);

  useEffect(() => {
    // Default Lang
    if (props.data.header.siteSettings.showLanguages) {
      const selectedLangId = getLanguageCookie();
      let selectedLang: ISiteSettingsLanguage | undefined = undefined;

      //check if selected language exists in props
      if (selectedLangId) {
        selectedLang = props.data.header.siteSettings.languages.find((l) => l.baseDirectory === selectedLangId);
      } else {
        selectedLang = props.data.header.siteSettings.languages.find((l) => l.isSelected);
      }
      dispatch({
        type: 'setDefaultLang',
        data: selectedLang ? selectedLang.countryCodeISO2 : props.data.header.siteSettings.languages[0].countryCodeISO2,
      });
    }

    if (props.data.header.siteSettings.showCurrencies) {
      dispatch({
        type: 'setDefaultCurrencyId',
        data: getSiteCurrencyId(props.data.header.siteSettings.currencies),
      });
    }
  }, [
    props.data.header.siteSettings.currencies,
    props.data.header.siteSettings.languages,
    props.data.header.siteSettings.showCurrencies,
    props.data.header.siteSettings.showLanguages,
  ]);

  //-- Set site settings values --//
  useLayoutEffect(() => {
    setIsVATSelected(includeVat());
  }, [props.data]);

  //-- Get header totals onmount--//
  useLayoutEffect(() => {
    if (!currentUser.isPopulated) headerService.getHeaderTotals(userDispatch);
  }, [currentUser.isPopulated, userDispatch]);

  // animate basket total notification when total changes
  useEffect(() => {
    if (isMobileDevice() && currentUser.basketTotal?.value > 0) {
      dispatch({ type: 'animateBasketTotal' });

      const timer = setTimeout(() => {
        dispatch({ type: 'animateBasketTotal' });
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentUser.basketTotal, currentUser.basketTotal.value]);

  return (
    <>
      {/* TODO: check if needs to be updated after OKTA integration */}
      {/**Successful Sign In Toast */}
      <LoginToast
        labels={props.data.header.labels}
        customerName={currentUser?.customer.name ?? ''}
        showToast={state.showCustomerLoggedInToast !== undefined ? state.showCustomerLoggedInToast : false}
      />

      <header
        className={`${styles['header-container']} ${state.isMenuOpen ? styles['menu-open'] : ''} ${
          props.isCheckout ? styles['header-container-checkout'] : ''
        } `}
        onClick={onHeaderClick}>
        <Helmet>
          {props.data.header.metaData?.structuredData?.map((sd) => {
            return <script {...getTagAttributes(sd.attributes)}>{sd.innerHtml}</script>;
          })}
        </Helmet>
        <Card squareCorners={true} className={`page-content ${styles['header-card']}`}>
          <div className={`column`}>
            <div className={styles['top-links-container']}>
              <div className={`${styles['top-links']} ${styles['info-links']} row`}>
                {props.data.header.links &&
                  props.data.header.links.map((value) => {
                    return (
                      <Button
                        key={value.text}
                        href={value.url}
                        {...getAttributes(value.attributes)}
                        text={value.text}
                        buttonType='text'
                        className={styles.link}
                      />
                    );
                  })}
              </div>

              {(props.data.header.siteSettings.showCurrencies ||
                props.data.header.siteSettings.showLanguages ||
                props.data.header.siteSettings.showVATToggle) && (
                <div className={`${styles['top-links']} ${styles['site-settings-container']} row`}>
                  {/**Site Flag */}
                  {props.contextInfo.locale !== Locale.Export && props.contextInfo.locale !== Locale.Af && (
                    <FlagIcon className={styles['site-icon']} width={16} height={16} name={getSiteFlag()} />
                  )}

                  {/**Language */}
                  {props.data.header.siteSettings.showLanguages && (
                    <div className={styles['site-setting']}>
                      <Button
                        key={state.defaultLang}
                        text={props.data.header.siteSettings.languages.find((l) => l.countryCodeISO2 === state.defaultLang)?.name}
                        buttonType='text'
                        icon='sort-down'
                        iconPosition='right'
                        className={styles.link}
                        onClick={() => dispatch({ type: 'toggleLanguageDropdown', isOpen: !state.isLanguageDropdownOpen })}
                      />
                      {state.isLanguageDropdownOpen && (
                        <Modal
                          isClosable={true}
                          hideCloseIcon={true}
                          hideOverlay={true}
                          className={styles['site-setting-modal-container']}
                          closeCallback={() => dispatch({ type: 'toggleLanguageDropdown', isOpen: !state.isLanguageDropdownOpen })}>
                          <div className={styles['site-setting-dropdown']}>
                            {props.data.header.siteSettings.languages.map((l) => {
                              return (
                                <p
                                  key={l.countryCodeISO2}
                                  onClick={() => {
                                    setSiteLanguage(props.data.header.siteSettings.languages, l.countryCodeISO2);
                                    dispatch({ type: 'toggleLanguageDropdown', isOpen: !state.isLanguageDropdownOpen });
                                  }}>
                                  {l.name}
                                </p>
                              );
                            })}
                          </div>
                        </Modal>
                      )}
                    </div>
                  )}
                  {/**Currency */}
                  {props.data.header.siteSettings.showCurrencies && (
                    <div className={styles['site-setting']}>
                      <Button
                        key={state.defaultCurrencyId}
                        text={props.data.header.siteSettings.currencies.find((c) => c.id === state.defaultCurrencyId)?.displayName}
                        buttonType='text'
                        icon='sort-down'
                        iconPosition='right'
                        className={styles.link}
                        onClick={() => dispatch({ type: 'toggleCurrencyDropdown', isOpen: !state.isCurrencyDropdownOpen })}
                      />
                      {state.isCurrencyDropdownOpen && (
                        <Modal
                          isClosable={true}
                          hideCloseIcon={true}
                          hideOverlay={true}
                          className={styles['site-setting-modal-container']}
                          closeCallback={() => dispatch({ type: 'toggleCurrencyDropdown', isOpen: !state.isCurrencyDropdownOpen })}>
                          <div
                            className={styles['site-setting-dropdown']}
                            onClick={() => dispatch({ type: 'toggleCurrencyDropdown', isOpen: !state.isCurrencyDropdownOpen })}>
                            {props.data.header.siteSettings.currencies.map((c) => {
                              return (
                                <p
                                  key={c.id}
                                  onClick={() => {
                                    setSiteCurrency(props.data.header.siteSettings.currencies, c.id);
                                    dispatch({ type: 'toggleCurrencyDropdown', isOpen: !state.isCurrencyDropdownOpen });
                                  }}>
                                  {c.displayName}
                                </p>
                              );
                            })}
                          </div>
                        </Modal>
                      )}
                    </div>
                  )}
                  {/* VAT */}
                  {props.data.header.siteSettings.showVATToggle && (
                    <div className={styles.vat}>
                      <p className={isVATSelected ? styles.unselected : ''}>{getLabel(props.data.header.labels, 'ex_vat')}</p>
                      <Switch id='switch-vat-header-menu' checkedValue={isVATSelected} onChange={(e) => setVat(e)}></Switch>
                      <p className={!isVATSelected ? styles.unselected : ''}>{getLabel(props.data.header.labels, 'inc_vat')}</p>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={`${styles['nav-bar']} row`}>
              <a href='/' className={`${styles.logo}`}>
                <Logo title='RS logo' />
              </a>

              <div className={`${styles['nav-bar-icons']} row`}>
                <a href='/' className={styles['logo-mobile']}>
                  <Logo title='RS logo' />
                </a>
                {!props.isCheckout && (
                  <>
                    {/* menu */}
                    <div className={`column ${styles['icon-button']} ${styles.menu}`} onClick={onMenuClick}>
                      <div className={`${styles.icon} ${styles['menu-icon-wrapper']}`}>
                        <div className={`${styles['menu-icon-container']} ${state.isMenuOpen ? styles.open : ''}`}>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      <span className={styles.info}>{getLabel(props.data.header.labels, 'menu')}</span>
                      {state.isMenuOpen && (
                        <Icon
                          name='sort-down'
                          width={32}
                          height={32}
                          className={`${styles.pointer} ${styles['menu-pointer']} 
                    ${currentUser?.customer.isLoggedIn ? styles['logged-in'] : ''}`}></Icon>
                      )}
                    </div>

                    {/* search */}
                    <div className={styles['search-container']}>
                      <SearchField
                        predictiveSearchService={props.predictiveSearchService}
                        placeholder={getLabel(props.data.header.labels, 'find_what_you_need')}
                        isPredictiveSearchEnabled={true}
                        labels={props.data.header.labels}></SearchField>
                    </div>
                    <Icon name='search' className={styles['search-icon']} onClick={onSearchClick} tabIndex={-1}></Icon>

                    {/* parts list */}
                    <div className={`column ${styles['icon-button']} ${styles['parts-list']}`} onClick={onPartListClick}>
                      <div className={`row ${styles['icon-container']}`}>
                        <Icon name='star-outline' className={`${styles.icon} ${styles['parts-list-icon']}`}></Icon>
                        {currentUser.noOfPartsLists > 0 && (
                          <div className={styles.notification}>
                            <span>{currentUser.noOfPartsLists}</span>
                          </div>
                        )}
                      </div>
                      <div className={styles.info}>
                        <span className={styles.title}>{getLabel(props.data.header.labels, 'parts_list')}</span>
                        {/* <Icon name='sort-down' width={16} height={16} className={styles.down}></Icon> */}
                      </div>
                    </div>

                    {/* account */}
                    <div
                      className={`column ${styles['icon-button']} ${styles.account}`}
                      onClick={onAccountClick}
                      data-test={getAccountTestTag()}>
                      <Icon name='account' className={styles.icon}></Icon>

                      <div className={styles.info}>
                        <span className={styles.title}>{getAccountString()}</span>{' '}
                        {/* <Icon name='sort-down' width={16} height={16} className={styles.down}></Icon> */}
                      </div>
                    </div>
                  </>
                )}
                {/* basket */}
                <div
                  className={`column ${styles['icon-button']} ${styles.basket} ${state.animateBasketTotal ? styles.animate : ''}`}
                  onClick={onBasketClick}>
                  <div className={`row ${styles['icon-container']} ${styles['basket-details']}`}>
                    <Icon name='basket' className={styles.icon}></Icon>
                    {/* notification if basket not empty */}
                    {currentUser.noOfItemsInBasket > 0 && (
                      <div className={`${styles.notification} ${styles['basket-notification']}`}>
                        <span>{currentUser.noOfItemsInBasket}</span>
                      </div>
                    )}
                  </div>
                  <span className={styles.info}>
                    {/*https://app.clickup.com/t/2vecfun*/}
                    {['int', 'kz', 'az'].includes(props.contextInfo.locale.toLowerCase()) ? (
                      <span className={styles.title}>{getLabel(props.data.header.labels, 'title')}</span>
                    ) : (
                      <>
                        <span className={styles.title}>{currentUser.basketTotal?.displayValue}</span>
                        <span className={`${styles.vat} ${!currentUser.basketTotal?.value ? 'hide' : ''}`}>
                          {getLabel(props.data.header.labels, 'exvat')}
                        </span>
                        {/* <Icon
                            name='sort-down'
                            width={16}
                            height={16}
                            className={`${styles.down} ${!props.data.basketTotal?.value ? 'hide' : ''}`}></Icon> */}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </header>
    </>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'toggleMenu':
        return {
          ...state,
          isMenuOpen: action.isOpen,
        };
      case 'toggleLanguageDropdown':
        return {
          ...state,
          isLanguageDropdownOpen: action.isOpen,
          isCurrencyDropdownOpen: false,
        };
      case 'toggleCurrencyDropdown':
        return {
          ...state,
          isCurrencyDropdownOpen: action.isOpen,
          isLanguageDropdownOpen: false,
        };
      case 'setDefaultLang': {
        return {
          ...state,
          defaultLang: action.data,
        };
      }
      case 'setDefaultCurrencyId': {
        return {
          ...state,
          defaultCurrencyId: action.data,
        };
      }
      case 'animateBasketTotal': {
        return {
          ...state,
          animateBasketTotal: !state.animateBasketTotal,
        };
      }
    }
  }

  function onMenuClick(e: MouseEvent<HTMLDivElement>) {
    toggleMenu(!state.isMenuOpen);
    e.stopPropagation();
  }

  function onSearchClick() {
    props.menuSubject?.next(false);
    props.toggleSearch(true);
  }

  function onAccountClick() {
    if (currentUser?.customer.isLoggedIn) {
      if (props.enableMyAccount) history.push('/user/myaccount');
      else window.location.href = '/myaccount/myaccount';
    } else triggerAuthentication();
  }

  function onBasketClick() {
    window.location.href = '/basket';
  }

  function onPartListClick() {
    if (currentUser?.customer.isLoggedIn) {
      // Opens the parts list
      setPartsListProps({});
    } else triggerAuthentication();
  }

  function triggerAuthentication() {
    if (oktaAuthEnabled) {
      loginWithRedirect({ authorizationParams: { ui_locales: props.data.master.metaData.htmlLang } });
    } else if (window.location.pathname !== '/user/login') {
      window.location.href = `/user/login${
        window.location.pathname.length === 0 || window.location.pathname === '/' ? '' : `?redirectTo=${window.location.pathname}`
      }`;
    }
  }

  function onHeaderClick() {
    toggleMenu(false);
  }

  function toggleMenu(isOpen: boolean) {
    props.menuSubject?.next(isOpen);
    if (isOpen) props.toggleSearch(false);

    dispatch({ type: 'toggleMenu', isOpen: isOpen });
  }

  function getAccountString(): string {
    if (!oktaAuthEnabled)
      return !currentUser?.customer || !currentUser?.customer.isLoggedIn
        ? getLabel(props.data.header.labels, 'logged_out_user')
        : getLabel(props.data.header.labels, 'account');
    else
      return !isLoading && isAuthenticated
        ? getLabel(props.data.header.labels, 'account')
        : getLabel(props.data.header.labels, 'logged_out_user');
  }

  function getAccountTestTag(): string {
    return !currentUser?.customer || !currentUser?.customer.isLoggedIn ? 'login_link' : 'account_link';
  }

  function setVat(e: ChangeEvent<HTMLInputElement>) {
    var checked = e.target.checked;
    setVatCookie(checked);
  }

  function getSiteFlag(): string {
    let siteLoc = props.contextInfo.locale.toString().toLowerCase();

    if (siteLoc === 'gr') {
      siteLoc = 'el';
    } else if (siteLoc === 'hr')
      siteLoc =
        props.data.header.siteSettings.languages.find((l) => l.countryCodeISO2 === state.defaultLang || l.isSelected)?.countryCodeISO2 ??
        siteLoc;

    return siteLoc;
  }
};
