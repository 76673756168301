import React, { FunctionComponent, useReducer } from 'react';
import { HeaderMenu, ProductMenu, Search } from '..';
import { IContextInfo } from '../../models/master/context-info.model';
import { IMasterData } from '../../models/master/master.model';
import { IMenuService } from '../../services/menu/menu-service';
import { IPartsListService } from '../../services/parts-list/parts-list-service';
import { IPredictiveSearchService } from '../../services/predictive-search/predictive-search-service';
import { IProductService } from '../../services/product/product-service';
import { Action, HeaderActionType, State } from './header-component.model';

type HeaderProps = {
  data: IMasterData;
  contextInfo: IContextInfo;
  isCheckout: boolean;
  menuService: IMenuService;
  predictiveSearchService: IPredictiveSearchService;
  partsListService: IPartsListService;
  productService?: IProductService;
  clickEvent: (eventDataAttributes: any) => void;
  enableMyAccount: boolean;
};

export const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const [state, dispatch] = useReducer(reducer, {
    isSearchOpen: false,
  });

  return (
    <>
      <HeaderMenu
        data={props.data}
        contextInfo={props.contextInfo}
        isCheckout={props.isCheckout}
        menuSubject={props.menuService.menuVisibilitySubject}
        predictiveSearchService={props.predictiveSearchService}
        partsListService={props.partsListService}
        productService={props.productService}
        toggleSearch={openSearch}
        enableMyAccount={props.enableMyAccount}
      />
      {state.isSearchOpen && (
        <Search toggleSearch={openSearch} labels={props.data.header.labels} predictiveSearchService={props.predictiveSearchService} />
      )}
      <ProductMenu
        data={props.data.menu}
        siteSettings={props.data.header.siteSettings}
        menuSubject={props.menuService.menuVisibilitySubject}
        requestSubject={props.menuService.requestSubject}
        responseSubject={props.menuService.responseSubject}
        clickEvent={props.clickEvent}
        predictiveSearchService={props.predictiveSearchService}
      />
    </>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case HeaderActionType.ToggleSearch:
        return {
          ...state,
          isSearchOpen: action.isOpen,
        };
    }
  }

  function openSearch(force?: boolean) {
    dispatch({ type: HeaderActionType.ToggleSearch, isOpen: force ?? !state.isSearchOpen });
  }
};
