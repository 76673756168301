import loadable from '@loadable/component';

const ControlledPurchasing = loadable(
  () =>
    import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/controlled-purchasing/controlled-purchasing-component'),
  {
    resolveComponent: (components) => components.ControlledPurchasing,
  }
);

const MyAccountNavbar = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-navbar/my-account-navbar-component'),
  {
    resolveComponent: (components) => components.MyAccountNavbar,
  }
);

const MyAccountOverview = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/my-account-overview-component'),
  {
    resolveComponent: (components) => components.MyAccountOverview,
  }
);

const LastOrders = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/last-orders/last-orders-component'),
  {
    resolveComponent: (components) => components.LastOrders,
  }
);

const PartList = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/part-list/part-list-component'),
  {
    resolveComponent: (components) => components.PartList,
  }
);

const RecentlyViewedProducts = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account" */ './my-account/my-account-overview/recently-viewed-products/recently-viewed-products-component'
    ),
  {
    resolveComponent: (components) => components.RecentlyViewedProducts,
  }
);

const RecentlyViewedCategories = loadable(
  () =>
    import(
      /* webpackChunkName: "my-account" */ './my-account/my-account-overview/recently-viewed-categories/recently-viewed-categories-component'
    ),
  {
    resolveComponent: (components) => components.RecentlyViewedCategories,
  }
);

const RecentlyViewedBrands = loadable(
  () =>
    import(/* webpackChunkName: "my-account" */ './my-account/my-account-overview/recently-viewed-brands/recently-viewed-brands-component'),
  {
    resolveComponent: (components) => components.RecentlyViewedBrands,
  }
);

const MyAccountDiscoverMore = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-discover-more/my-account-discover-more-component'),
  {
    resolveComponent: (components) => components.MyAccountDiscoverMore,
  }
);

const OrderProductList = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-orders/order-product-list/order-product-list-component'),
  {
    resolveComponent: (components) => components.OrderProductList,
  }
);

const OrderList = loadable(
  () => import(/* webpackChunkName: "my-account" */ './my-account/my-account-orders/order-list/order-list-component'),
  {
    resolveComponent: (components) => components.OrderList,
  }
);

const ForgotPasswordPage = loadable(() => import(/* webpackChunkName: "my-account" */ './forgot-password/forgot-password-component'), {
  resolveComponent: (components) => components.ForgotPasswordPage,
});

const LoginPage = loadable(() => import(/* webpackChunkName: "login" */ './login/login-page/login-page-component'), {
  resolveComponent: (components) => components.LoginPage,
});

const LoginToast = loadable(() => import(/* webpackChunkName: "login" */ './login/login-toast/login-toast-component'), {
  resolveComponent: (components) => components.LoginToast,
});

const ProfileField = loadable(() => import(/* webpackChunkName: "my-account" */ './profile-field/profile-field-component'), {
  resolveComponent: (components) => components.ProfileField,
});

const ProfilePage = loadable(() => import(/* webpackChunkName: "my-account" */ './my-account/profile-page/profile-page-component'), {
  resolveComponent: (components) => components.ProfilePage,
});

const RegistrationPage = loadable(() => import(/* webpackChunkName: "my-account" */ './registration-page/registration-page-component'), {
  resolveComponent: (components) => components.RegistrationPage,
});

const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "my-account" */ './reset-password/reset-password-component'), {
  resolveComponent: (components) => components.ResetPasswordPage,
});

const SubscriptionPage = loadable(() => import(/* webpackChunkName: "subscription" */ './subscription/subscription-page-component'), {
  resolveComponent: (components) => components.SubscriptionPage,
});

const UnsubscriptionPage = loadable(() => import(/* webpackChunkName: "subscription" */ './unsubscription/unsubscription-page-component'), {
  resolveComponent: (components) => components.UnsubscriptionPage,
});

export {
  ControlledPurchasing,
  ForgotPasswordPage,
  LastOrders,
  LoginPage,
  LoginToast,
  MyAccountDiscoverMore,
  MyAccountNavbar,
  MyAccountOverview,
  OrderProductList,
  OrderList,
  PartList,
  ProfileField,
  ProfilePage,
  RecentlyViewedProducts,
  RecentlyViewedCategories,
  RecentlyViewedBrands,
  RegistrationPage,
  ResetPasswordPage,
  SubscriptionPage,
  UnsubscriptionPage,
};
