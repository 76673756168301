import React, { FunctionComponent } from 'react';
import { Button, PageAlert } from 'rs-emd-ui-atoms';
import { SiteWideBanner as Banner } from '../../../../generated/graphql';
import styles from './site-wide-banner-component.module.scss';

type SiteWideBannerProps = {
  data: Banner;
};

export const SiteWideBanner: FunctionComponent<SiteWideBannerProps> = (props: SiteWideBannerProps) => {
  return (
    <div className={styles['site-wide-banner']}>
      <PageAlert
        icon={props.data.type === 'information' ? 'info-outline' : props.data.type === 'warning' ? 'bell' : 'warning-outline'}
        colour={props.data.type === 'information' ? 'teal' : props.data.type === 'warning' ? 'yellow' : 'pink'}>
        <div className={styles['site-wide-banner-content']}>
          <p className={styles['site-wide-banner-headline']}>{props.data.headline}</p>
          {props.data.body && <p className={styles['site-wide-banner-body']}>{props.data.body}</p>}
          {props.data.buttonText && props.data.buttonUrl && (
            <Button href={props.data.buttonUrl} text={props.data.buttonText} buttonType='outline' className={styles.btn} />
          )}
        </div>
      </PageAlert>
    </div>
  );
};
