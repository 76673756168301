//https://react.dev/learn/scaling-up-with-reducer-and-context#

import React, { Dispatch, FunctionComponent, createContext, useContext, useReducer } from 'react';
import { IFormattedNumber } from '../../rs-emd-ui-modules/src/models/common/formatted-number.model';
import { ICustomer } from '../../rs-emd-ui-modules/src/models/master/header/header-menu.model';

type UserContext = {
  isPopulated: boolean;
  basketTotal: IFormattedNumber;
  noOfItemsInBasket: number;
  noOfPartsLists: number;
  customer: ICustomer;
};

export enum UserContextActionType {
  UpdateBasketTotal = 'UpdateBasketTotal',
  UpdatePartsListsTotal = 'UpdatePartsListsTotal',
  UpdateHeaderTotals = 'UpdateHeaderTotals',
  UpdateCustomer = 'UpdateCustomer',
}

export interface UserContextAction {
  type: UserContextActionType;
  basketTotal?: IFormattedNumber;
  noOfItemsInBasket?: number;
  noOfPartsLists?: number;
  customer?: ICustomer;
}

const initialUserContext: UserContext = {
  isPopulated: false,
  basketTotal: {
    value: 0,
    displayValue: '0.00',
  },
  noOfItemsInBasket: 0,
  noOfPartsLists: 0,
  customer: {
    isLoggedIn: false,
  },
};

export const UserContext = createContext<UserContext>(initialUserContext);
export const UserDispatchContext = createContext<Dispatch<UserContextAction> | null>(null);

export const UserProvider: FunctionComponent = (props: any) => {
  const [user, dispatch] = useReducer(userReducer, initialUserContext);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>{props.children}</UserDispatchContext.Provider>
    </UserContext.Provider>
  );

  function userReducer(user: UserContext, action: UserContextAction): UserContext {
    switch (action.type) {
      case UserContextActionType.UpdateBasketTotal: {
        return {
          ...user,
          basketTotal: action.basketTotal ?? user.basketTotal,
          noOfItemsInBasket: action.noOfItemsInBasket ?? user.noOfItemsInBasket,
        };
      }
      case UserContextActionType.UpdatePartsListsTotal: {
        return {
          ...user,
          noOfPartsLists: action.noOfPartsLists ?? user.noOfPartsLists,
        };
      }
      case UserContextActionType.UpdateHeaderTotals: {
        return {
          ...user,
          isPopulated: true,
          basketTotal: action.basketTotal ?? user.basketTotal,
          noOfItemsInBasket: action.noOfItemsInBasket ?? user.noOfItemsInBasket,
          noOfPartsLists: action.noOfPartsLists ?? user.noOfPartsLists,
          customer: action.customer ?? user.customer,
        };
      }
      case UserContextActionType.UpdateCustomer: {
        return {
          ...user,
          customer: action.customer ?? user.customer,
        };
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }
};

export function useUser() {
  return useContext(UserContext);
}

export function useUserDispatch() {
  return useContext(UserDispatchContext);
}
