import { getCookie, setCookie } from '..';

export function includeVat(): boolean {
  return getCookie('IncludeVat') === '1' ? true : false;
}

export function setVatCookie(isSelected: boolean, timeout: boolean = true) {
  setTimeout(
    function (e) {
      setCookie('IncludeVat', isSelected ? '1' : '0');
      window.location.reload();
    },
    timeout ? 300 : 0,
    isSelected
  );
}

export function getUserSessionId(): string | undefined {
  return getCookie('ASP.NET_SessionId');
}

export function setCurrencyCookie(id?: string, shortName?: string) {
  setCookie('DefaultCurrencyId', id ?? '');
  setCookie('CurrencyShortName', shortName ?? '');
}

export function getCurrencyCookie(): string | undefined {
  return getCookie('DefaultCurrencyId');
}

export function getCurrencyShortName(): string | undefined {
  return getCookie('CurrencyShortName');
}

export function setLanguageCookie(baseDirectory: string, deleteCookie: boolean = false) {
  baseDirectory = baseDirectory ?? '';

  if (deleteCookie) {
    setCookie('BaseDirectory', baseDirectory, -1);
  } else {
    setCookie('BaseDirectory', baseDirectory);
  }
}

export function getLanguageCookie(): string | undefined {
  return getCookie('BaseDirectory');
}

export function setPLPFullScreenCookie(val: boolean) {
  setCookie('PLPFullScreen', String(val));
}

export function getPLPFullScreenCookie(): boolean {
  return getCookie('PLPFullScreen') === 'true';
}

export function allowAnalyticsCookies(): boolean | undefined {
  let cookieVal = getCookie('RSCOMPONENTS_ENSIGHTEN_PRIVACY_Analytics');
  if (cookieVal === undefined) return cookieVal;
  return cookieVal === '1';
}

export function allowAdvertisingCookies(): boolean | undefined {
  let cookieVal = getCookie('RSCOMPONENTS_ENSIGHTEN_PRIVACY_Advertising');
  if (cookieVal === undefined) return cookieVal;
  return cookieVal === '1';
}

export function allowFuntionalityCookies(): boolean | undefined {
  let cookieVal = getCookie('RSCOMPONENTS_ENSIGHTEN_PRIVACY_Functionality');
  if (cookieVal === undefined) return cookieVal;
  return cookieVal === '1';
}

export function privacyModalViewed(): boolean {
  return getCookie('RSCOMPONENTS_ENSIGHTEN_PRIVACY_MODAL_VIEWED') === '1';
}

export function setPreviewPricesCookie(date: string | undefined) {
  setCookie('PreviewPrices', date ?? '');
}

export function getPreviewPricesCookie(): string | undefined {
  return getCookie('PreviewPrices');
}

export function setAccountOrdersViewByCookie(value: string) {
  setCookie('ViewBy', value, 365);
}

export function getAccountOrdersViewByCookie(): string | undefined {
  return getCookie('ViewBy');
}
