import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Icon, isDesktop, isLargeDesktop, isMobileDevice, isTabletDevice, Radio } from 'rs-emd-ui-atoms';
import { PageSizeControls } from '../..';
import { getLabel } from '../../../../helpers/html.utils';
import { ILabel } from '../../../../models/common/label.model';
import { IPagination } from '../pagination';
import styles from './pagination-component.module.scss';

type PaginationProps = {
  labels?: ILabel[] | null;
  pagination: IPagination;
  onPageSizeChange: (pagination: IPagination) => void;
  alwaysShowPageSizeControls?: boolean;
  className?: string;
};

enum VisibleLoc {
  BEGINNING,
  MIDDLE,
  END,
}

export const Pagination: FunctionComponent<PaginationProps> = (props: PaginationProps) => {
  const [visiblePagesAtEnds, setVisiblePagesAtEnds] = useState<number>(getVisiblePagesAtEnds());
  const [maxVisiblePages, setMaxVisiblePages] = useState<number>(getMaxVisiblePages());
  const [selectedPage, setSelectedPage] = useState<number>(1 + props.pagination.offset / props.pagination.size);
  const [visiblePagesList, setVisiblePagesList] = useState<Array<number>>(Array.from({ length: maxVisiblePages }, (_, i) => i + 1));
  const [visiblePagesLoc, setVisiblePagesLoc] = useState<VisibleLoc>(VisibleLoc.BEGINNING);

  const previousButton = (
    <div className={`${styles['nav-area']} ${selectedPage === 1 ? styles['nav-disabled'] : ''}`}>
      <Icon
        name='chev-left'
        color='#1545a3'
        className={`${selectedPage === 1 ? styles['chev-disabled'] : ''} ${styles['chev-prev']}`}
        onClick={() => goToPage(selectedPage - 1)}
      />
      <Button
        disabled={selectedPage === 1}
        className={styles['prev-button']}
        buttonType='text'
        text={getLabel(props.labels, 'pager_prev')}
        onClick={() => goToPage(selectedPage - 1)}
      />
    </div>
  );

  const nextButton = (
    <div className={`${styles['nav-area']} ${selectedPage === props.pagination.numberOfPages ? styles['nav-disabled'] : ''}`}>
      <Button
        disabled={selectedPage === props.pagination.numberOfPages}
        className={styles['next-button']}
        buttonType='text'
        text={getLabel(props.labels, 'pager_next')}
        onClick={() => goToPage(selectedPage + 1)}
      />
      <Icon
        name='chev-right'
        color='#1545a3'
        className={`${selectedPage === props.pagination.numberOfPages ? styles['chev-disabled'] : ''} ${styles['chev-next']}`}
        onClick={() => goToPage(selectedPage + 1)}
      />
    </div>
  );

  //create list of visible pages as radio button elements
  const generateVisiblePages = visiblePagesList.map((pageNumber) => ({ key: pageNumber, elt: <span>{pageNumber}</span> }));

  const pageSelection = (
    <div className={styles['page-selection']}>
      <Radio
        name='page-radio-beginning'
        defaultSelection={selectedPage}
        list={visiblePagesLoc === VisibleLoc.BEGINNING ? generateVisiblePages : [{ key: 1, elt: <span>1</span> }]}
        spacing={0.8}
        size='small'
        selectCallback={(key) => goToPage(parseInt(key.toString()))}
      />

      {props.pagination.numberOfPages > maxVisiblePages && (
        <>
          {visiblePagesLoc === VisibleLoc.MIDDLE && (
            <>
              <p className={styles['more-pages']}>...</p>
              <Radio
                name='page-radio-middle'
                defaultSelection={selectedPage}
                list={generateVisiblePages}
                spacing={0.8}
                size={'small'}
                selectCallback={(key) => goToPage(parseInt(key.toString()))}
              />
            </>
          )}
          <p className={styles['more-pages']}>...</p>
          <Radio
            name='page-radio-end'
            defaultSelection={selectedPage}
            list={
              visiblePagesLoc === VisibleLoc.END
                ? generateVisiblePages
                : [{ key: props.pagination.numberOfPages, elt: <span>{props.pagination.numberOfPages}</span> }]
            }
            spacing={0.8}
            size={'small'}
            selectCallback={(key) => goToPage(parseInt(key.toString()))}
          />
        </>
      )}
    </div>
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };

    function onResize() {
      setMaxVisiblePages(getMaxVisiblePages());
      setVisiblePagesAtEnds(getVisiblePagesAtEnds());
    }
  }, []);

  useEffect(() => {
    setSelectedPage(Math.ceil(props.pagination.offset / props.pagination.size + 1));
  }, [props.pagination]);

  useEffect(() => {
    if (props.pagination.numberOfPages > maxVisiblePages) {
      let startPages = Array.from({ length: visiblePagesAtEnds }, (_, i) => i + 1);
      let endPages = Array.from(Array.from(Array(visiblePagesAtEnds).keys()).reverse(), (x) => props.pagination.numberOfPages - x);

      if (selectedPage !== visiblePagesAtEnds && startPages.includes(selectedPage)) {
        setVisiblePagesList(startPages); //show first set of visible pages at ends
        setVisiblePagesLoc(VisibleLoc.BEGINNING);
      } else if (selectedPage !== endPages[0] && endPages.includes(selectedPage)) {
        setVisiblePagesList(endPages); //show last set of visible pages at ends
        setVisiblePagesLoc(VisibleLoc.END);
      } else {
        //create array [-1, 0, 1] for mob else [-1, 0, 1, 2, 3, 4] and add to selectedPage
        let middlePages = Array.from({ length: visiblePagesAtEnds - 1 }, (_, i) => selectedPage + i - 1);
        setVisiblePagesList(middlePages); //show middle pages
        setVisiblePagesLoc(VisibleLoc.MIDDLE);
      }
    } else {
      setVisiblePagesList(Array.from({ length: props.pagination.numberOfPages }, (_, i) => i + 1)); //show all pages
      setVisiblePagesLoc(VisibleLoc.BEGINNING);
    }
  }, [selectedPage, props.pagination.numberOfPages, maxVisiblePages, visiblePagesAtEnds]);

  return (
    <div
      className={`${styles['pagination-component']} ${props.alwaysShowPageSizeControls ? styles['always-show-page-size'] : ''} ${
        props.className ?? ''
      }`}>
      {props.pagination.numberOfPages > 0 && (
        <>
          <div className={styles['page-size-controls']}>
            <PageSizeControls labels={props.labels} pagination={props.pagination} onChange={props.onPageSizeChange} />
          </div>
          {props.pagination.numberOfPages > 1 && (
            <div className={styles['pages']}>
              {isMobileDevice() ? (
                <>
                  <div className={styles['mobile-nav-buttons']}>
                    {previousButton}
                    {nextButton}
                  </div>
                  {pageSelection}
                </>
              ) : (
                <>
                  {previousButton}
                  {pageSelection}
                  {nextButton}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  function getVisiblePagesAtEnds() {
    if (isMobileDevice()) {
      return 4;
    }

    return 7;
  }

  function getMaxVisiblePages() {
    if (isLargeDesktop() /*|| isXLargeDesktop()*/) {
      return 9;
    } else if (isTabletDevice() || isDesktop()) {
      return 10;
    } else {
      //if mobile device
      return 6;
    }
  }

  function goToPage(page: number) {
    setSelectedPage(page);

    let pagination = props.pagination;
    pagination.offset = (page - 1) * props.pagination.size;
    props.onPageSizeChange(pagination);
  }
};
