import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { ResetPasswordPage as ResetPasswordPageData, useResetPasswordPageQuery } from '../../generated/graphql';
import { ResetPasswordPage } from '../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { useUser } from '../app/user-context';

type ResetPasswordWrapperProps = {
  enableMyAccount: boolean;
};

export const ResetPasswordWrapper: FunctionComponent<ResetPasswordWrapperProps> = (props: ResetPasswordWrapperProps) => {
  let { pathname, search } = useLocation();
  const { customer } = useUser();

  const token = new URLSearchParams(search).get('token');
  const redirectToMyAccount = props.enableMyAccount && pathname.toLowerCase().endsWith('/user/resetpassword') && customer?.isLoggedIn;

  const { loading, error, data } = useResetPasswordPageQuery({
    variables: { token: token ?? '' },
    ssr: true,
    skip: redirectToMyAccount,
  });

  if (redirectToMyAccount) return <RedirectWithStatus to={'/user/myaccount/resetPassword'} statusCode={302} />;
  if (loading) {
    return <Loader />;
  } else if (error || !data || (!token && !customer?.isLoggedIn)) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }
  //if token verification failed
  else if (!data.resetPasswordPage.isSuccess) {
    return <Redirect to={{ pathname: '/user/forgotPassword', state: { invalidToken: true } }} />;
  }

  return <ResetPasswordPage data={data.resetPasswordPage as ResetPasswordPageData} token={token} enableMyAccount={props.enableMyAccount} />;
};

export default ResetPasswordWrapper;
