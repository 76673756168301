import { createContext } from 'react';
import { PartsListsProps } from '../../rs-emd-ui-modules/src/components/parts-list/part-lists/part-lists-component';

export type PartsListsContextProps = Omit<PartsListsProps, 'closeCallback'>;

interface IPartsListContextValue {
  partsListProps?: PartsListsContextProps;
  setPartsListProps: (props?: PartsListsContextProps) => void;
}

export const PartsListContext = createContext<IPartsListContextValue>({ setPartsListProps: () => {} });
