import React, { FunctionComponent } from 'react';
import { Button, Dropdown } from 'rs-emd-ui-atoms';
import { OrderDetailsParcelItem } from '../../../../../../../../generated/graphql';
import { clickEvent } from '../../../../../../../../tagging/ensighten';
import { getLabel } from '../../../../../../helpers/html.utils';
import { ILabel } from '../../../../../../models/common/label.model';
import styles from './order-summary-item-component.module.scss';

export type OrderSummaryItemProps = {
  orderId: number;
  labels?: ILabel[];
  parcelItem?: OrderDetailsParcelItem;
};

export const OrderSummaryItem: FunctionComponent<OrderSummaryItemProps> = (props: OrderSummaryItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles['product-details-container']}>
        <img src={props.parcelItem?.product.title.primaryImageUrl} alt={props.parcelItem?.product.title.title} className={styles.thumb} />
        <div className={styles['product-details']}>
          <span className={styles['product-title']}>{props.parcelItem?.product.title.title}</span>
          <span>
            {getLabel(props.labels, 'stock_code')}: {props.parcelItem?.product.title.stockCode}
          </span>
          <span>
            {getLabel(props.labels, 'brand')}:&nbsp;
            <a href={`/productlist/search?query=${props.parcelItem?.product.title.brand.toLowerCase()}`} className={styles.brand}>
              {props.parcelItem?.product.title.brand}
            </a>
          </span>
          <span>
            {getLabel(props.labels, 'mpn')}: {props.parcelItem?.product.title.mpn}
          </span>
          {props.parcelItem?.product.title.partNumber && (
            <span>
              {getLabel(props.labels, 'your_part_number')}: {props.parcelItem?.product.title.partNumber}
            </span>
          )}
        </div>
      </div>
      <div className={styles['price-container']}>
        <div className={styles['quantity-container']}>
          <span className={styles.bold}>
            {getLabel(props.labels, 'quantity_ordered')}: {props.parcelItem?.quantity}
          </span>
          <div className={styles['price-table']}>
            <div className={styles['price-row']}>
              <span>{props.parcelItem?.product.priceArea.unitPrice.priceBefore.displayValue}</span>
              <span className={styles.bold}>{props.parcelItem?.product.priceArea.totalPrice.priceBefore.displayValue}</span>
            </div>
            <div className={styles['price-row']}>
              <span className={styles.desc}>{getLabel(props.labels, 'uom_ea')}</span>
              <span className={styles.desc}>{getLabel(props.labels, 'total')}</span>
            </div>
          </div>
        </div>

        <div className={styles['reorder-container']}>
          <span className={styles['reorder-item']}>{getLabel(props.labels, 'reorder_item')}</span>
          <div className={styles['reorder-inputs']}>
            <Dropdown list={[]} />
            <Button text={getLabel(props.labels, 'add_to_basket')} onClick={onAddToBasket} />
          </div>
        </div>
      </div>
    </div>
  );

  function onAddToBasket() {
    const attributes: any = {
      __Type: 'buttonTracker',
      __Info: 'Add to basket from Orders',
      __Result: `${props.orderId.toString()}, ${props.parcelItem?.product.title.stockCode}`,
    };

    clickEvent(attributes);
  }
};
