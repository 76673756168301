import { Observable, Subject } from 'rxjs';
import { PDPRequest, PDPResponse } from '../rs-emd-ui-modules/src/components/product-detail-page/pdp.dto';
import {
  AddRecentlyViewedDocument,
  AddRecentlyViewedMutation,
  AddRecentlyViewedMutationVariables,
  DataSheetsDocument,
  DataSheetsQuery,
  DataSheetsQueryVariables,
  GetCadDocumentsDocument,
  GetCadDocumentsQuery,
  GetCadDocumentsQueryVariables,
  GetTracePartsDocumentUrlDocument,
  GetTracePartsDocumentUrlQuery,
  GetTracePartsDocumentUrlQueryVariables,
  UpdateCalibrationStockCodeDataDocument,
  UpdateCalibrationStockCodeDataQuery,
  UpdateCalibrationStockCodeDataQueryVariables,
  UpdatePpkStockCodeDataDocument,
  UpdatePpkStockCodeDataQuery,
  UpdatePpkStockCodeDataQueryVariables,
} from './../generated/graphql';
import { PDPQueryType } from './../rs-emd-ui-modules/src/components/product-detail-page/pdp.dto';
import { graphQLService } from './graphql-service';
import { pushEvent } from '../tagging/ensighten';

export class PDPService {
  public responseObservable: Observable<PDPResponse>;

  constructor(readonly requestSubject = new Subject<PDPRequest>(), private readonly responseSubject = new Subject<PDPResponse>()) {
    this.responseObservable = this.responseSubject.asObservable();

    this.watchRequestSubject();
  }

  watchRequestSubject() {
    this.requestSubject.subscribe((req) => {
      switch (req.type) {
        case PDPQueryType.UpdatePPKData:
          this.updatePpkStockCodeData(req.stockCode);
          return;
        case PDPQueryType.UpdateCalibrationData:
          this.updateCalibrationStockCodeData(req.stockCode);
          return;
        case PDPQueryType.GetDataSheets:
          this.getDataSheets(req.stockCode, req.documentsType);
          return;
        case PDPQueryType.GetCadDocuments:
          this.getCadDocuments(req.stockCode, req.tracePartsPartId);
          return;
        case PDPQueryType.GetTracePartsDocumentUrl:
          this.getTracePartsDocumentUrl(req.stockCode, req.documentId, req.tracePartsPartId);
          return;
      }
    });
  }

  pushEvent(eventKey: string, eventData: any): void {
    return pushEvent(eventKey, eventData);
  }

  updatePpkStockCodeData(stockCode: string) {
    graphQLService.apolloClient
      .query<UpdatePpkStockCodeDataQuery, UpdatePpkStockCodeDataQueryVariables>({
        query: UpdatePpkStockCodeDataDocument,
        variables: { stockCode: stockCode },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        result.data &&
          this.responseSubject.next({
            type: PDPQueryType.UpdatePPKData,
            data: {
              priceArea: result.data.productDetailPage.priceArea,
              stockArea: result.data.productDetailPage.stockArea,
              titleArea: result.data.productDetailPage.titleArea,
            },
          });
      });
  }

  updateCalibrationStockCodeData(stockCode: string) {
    graphQLService.apolloClient
      .query<UpdateCalibrationStockCodeDataQuery, UpdateCalibrationStockCodeDataQueryVariables>({
        query: UpdateCalibrationStockCodeDataDocument,
        variables: { stockCode: stockCode },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        result.data &&
          this.responseSubject.next({
            type: PDPQueryType.UpdateCalibrationData,
            data: {
              priceArea: result.data.productDetailPage.priceArea,
              stockArea: result.data.productDetailPage.stockArea,
              titleArea: result.data.productDetailPage.titleArea,
            },
          });
      });
  }

  getDataSheets(stockCode: string, documentsType: string) {
    graphQLService.apolloClient
      .query<DataSheetsQuery, DataSheetsQueryVariables>({
        query: DataSheetsDocument,
        variables: { stockCode: stockCode, documentsType: documentsType },
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        result &&
          result.data &&
          this.responseSubject.next({
            type: PDPQueryType.GetDataSheets,
            data: {
              dataSheets: result.data.dataSheets,
              documentsType: documentsType,
            },
          });
      });
  }

  getCadDocuments(stockCode: string, tracePartsPartId?: string | null) {
    graphQLService.apolloClient
      .query<GetCadDocumentsQuery, GetCadDocumentsQueryVariables>({
        query: GetCadDocumentsDocument,
        variables: { stockCode: stockCode, tracePartsPartId: tracePartsPartId },
      })
      .then((result) => {
        result?.data &&
          this.responseSubject.next({
            type: PDPQueryType.GetCadDocuments,
            data: {
              cadDocuments: result.data.cadDocuments,
            },
          });
      });
  }

  getTracePartsDocumentUrl(stockCode: string, documentId: number, tracePartsPartId?: string | null) {
    graphQLService.apolloClient
      .query<GetTracePartsDocumentUrlQuery, GetTracePartsDocumentUrlQueryVariables>({
        query: GetTracePartsDocumentUrlDocument,
        variables: { stockCode: stockCode, documentId: documentId, tracePartsPartId: tracePartsPartId },
      })
      .then((result) => {
        result?.data &&
          this.responseSubject.next({
            type: PDPQueryType.GetTracePartsDocumentUrl,
            data: {
              tracePartsDocumentUrl: result.data.tracePartsDocumentUrl,
            },
          });
      });
  }

  async addRecentlyViewed(stockCode: string, brand: string, familyId: number) {
    const result = await graphQLService.apolloClient.mutate<AddRecentlyViewedMutation, AddRecentlyViewedMutationVariables>({
      mutation: AddRecentlyViewedDocument,
      variables: { stockCode: stockCode, brand: brand, familyId: familyId },
    });

    return result.data?.addRecentlyViewed.isSuccess ?? false;
  }
}

export const pdpService = new PDPService();
