import React, { FunctionComponent, useState } from 'react';
import { Icon } from 'rs-emd-ui-atoms';
import { OrderSort, OrderSortFieldType, SortDirection } from '../../../../../../../../generated/graphql';
import { getLabel } from '../../../../../../helpers/html.utils';
import { ILabel } from '../../../../../../models/common/label.model';
import styles from './order-list-header-component.module.scss';

export type OrderListHeaderProps = {
  labels?: ILabel[];
  displayOnly?: boolean;
  showSortArrows?: boolean;
  onSortCallback?: (sortMode: OrderSort) => void;
  sortMode?: OrderSort;
  className?: string;
};

export const OrderListHeader: FunctionComponent<OrderListHeaderProps> = (props: OrderListHeaderProps) => {
  const [sortMode, setSortMode] = useState<{ field: OrderSortFieldType; isAscending: boolean } | undefined>(
    props.sortMode ? { field: props.sortMode.sortField, isAscending: props.sortMode?.direction === SortDirection.Ascending } : undefined
  );

  function getHeaderItem(field: OrderSortFieldType) {
    function toggleSort() {
      if (sortMode?.field === field) {
        setSortMode((val) => ({ field: val!.field, isAscending: !val!.isAscending }));
        props.onSortCallback &&
          props.onSortCallback({ sortField: field, direction: sortMode.isAscending ? SortDirection.Descending : SortDirection.Ascending });
      } else {
        setSortMode({ field: field, isAscending: true });
        props.onSortCallback && props.onSortCallback({ sortField: field, direction: SortDirection.Ascending });
      }
    }

    let icon = 'chev-sort';
    let iconWidth = 24;

    if (field === sortMode?.field) {
      icon = sortMode.isAscending ? 'chev-up' : 'chev-down';
      iconWidth = 20;
    }

    return (
      <div
        className={styles['header-title-container']}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            toggleSort();
          }
        }}>
        <span className={styles['header-title']} onClick={toggleSort}>
          {getLabel(props.labels, fieldToLabel(field))}
        </span>
        {!props.displayOnly && <Icon name={icon} width={iconWidth} className={styles['sort-arrow']} onClick={toggleSort} />}
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${props.className ?? ''}`}>
      {getHeaderItem(OrderSortFieldType.DateCreated)}
      {getHeaderItem(OrderSortFieldType.ReferenceWeb)}
      {getHeaderItem(OrderSortFieldType.CustomerOrderReference)}
      {getHeaderItem(OrderSortFieldType.GrandTotal)}
      {!props.displayOnly && <div className={styles['end-section']} />}
    </div>
  );

  function fieldToLabel(field: OrderSortFieldType) {
    switch (field) {
      case OrderSortFieldType.DateCreated:
        return 'date_ordered';
      case OrderSortFieldType.ReferenceWeb:
        return 'order_no';
      case OrderSortFieldType.CustomerOrderReference:
        return 'your_order_no';
      case OrderSortFieldType.GrandTotal:
        return 'total';
    }
  }
};

export default OrderListHeader;
