import React, { FunctionComponent } from 'react';
import { Badge, Button } from 'rs-emd-ui-atoms';
import { FormattedNumber, OrderDetailsPage, OrderHistoryOrder } from '../../../../../../../generated/graphql';
import { clickEvent } from '../../../../../../../tagging/ensighten';
import { getLabel } from '../../../../../helpers/html.utils';
import { MetaDataAndTagging } from '../../../../shared';
import { OrderList, OrderListOrder } from '../order-list/order-list-component';
import { DeliveryDetails } from './delivery-details/delivery-details-component';
import { DetailsAccordion } from './details-accordion/details-accordion-component';
import styles from './order-details-component.module.scss';
import { OrderSummaryItem } from './order-summary-item/order-summary-item-component';

type OrderDetailsProps = {
  orderId: number;
  data: OrderDetailsPage;
};

type Order = {
  totalItemPrice: FormattedNumber;
  deliveryPrice: FormattedNumber;
  vat: FormattedNumber;
} & OrderHistoryOrder;

export const OrderDetails: FunctionComponent<OrderDetailsProps> = (props: OrderDetailsProps) => {
  const backToOrdersButton = (
    <Button
      text={getLabel(props.data.labels, 'back_to_orders')}
      icon='chev-left'
      buttonType='link'
      href='/user/myaccount/orders'
      isExternalLink={false}
      className={styles['back-button']}
    />
  );

  return (
    <div className={`inner-page-wrapper ${styles['order-details-container']}`}>
      {/* Head data */}
      <MetaDataAndTagging tagging={props.data.tagging} metaData={props.data.metaData} />

      {backToOrdersButton}
      {props.data.details?.paymentOption?.details?.controlledPurchasingDetails ? (
        <div className={styles['controlled-purchasing-title']}>
          <Badge colour='green'>{getLabel(props.data.labels, 'controlled_purchasing')}</Badge>
          <span>
            {`${getLabel(props.data.labels, 'controlled_purchasing_sent_to')} ${
              props.data.details.paymentOption.details?.controlledPurchasingDetails?.purchaser?.emailAddress
            }
            `}
          </span>
        </div>
      ) : (
        <span className={styles.title}>
          {getLabel(props.data.labels, 'order')} {props.data.details?.orderReference}
        </span>
      )}
      <OrderList
        labels={props.data.labels}
        orders={[
          {
            orderId: Number(props.orderId),
            dateOrdered: props.data.details?.dateOrdered,
            orderReference: Number(props.data.details?.orderReference),
            orderReferencePersonal: props.data.details?.orderReferencePersonal,
            totalDisplayValue: props.data.details?.summary.grandTotal.price.displayValue,
          } as OrderListOrder,
        ]}
        displayOnly
      />
      {props.data.details && (
        <DetailsAccordion
          labels={props.data.labels}
          title={getLabel(props.data.labels, 'delivery_invoice_payment_details')}
          isOpenByDefault>
          <DeliveryDetails
            labels={props.data.labels}
            orderNum={props.data.details.orderReference}
            deliveryAddress={props.data.details.deliveryAddress}
            contactDetails={props.data.details.contactDetails}
            invoiceAddress={props.data.details.billingAddress}
            paymentMethod={props.data.details.paymentOption}
          />
        </DetailsAccordion>
      )}
      <DetailsAccordion labels={props.data.labels} title={getLabel(props.data.labels, 'order_summary')} isOpenByDefault>
        {props.data.details?.parcels.map((product) => (
          <OrderSummaryItem labels={props.data.labels} parcelItem={product} orderId={props.orderId} />
        ))}
      </DetailsAccordion>
      <div className={styles['summary-total-container']}>
        <div className={styles['summary-total']}>
          <div className={styles.column}>
            <span className={styles['num-items']}>
              {props.data.details?.parcels.length} {getLabel(props.data.labels, 'item_s')}
            </span>
            <span>{getLabel(props.data.labels, 'delivery')}</span>
            {props.data.details?.summary.surcharges.map((surcharge) => {
              return <div className={styles['summary-info']}>{surcharge.label}</div>;
            })}
            <span className={styles.total}>{getLabel(props.data.labels, 'total')}</span>
          </div>
          <div className={styles.column}>
            <span>{props.data.details?.summary.netTotal.price.displayValue}</span>
            <span>{props.data.details?.summary.deliveryCharge.price.displayValue}</span>
            {/**Surcharges */}
            {props.data.details?.summary.surcharges.map((surcharge) => {
              let cost = <span className={styles.cost}>{surcharge.price.displayValue}</span>;

              return (
                <div className={styles['summary-info']}>
                  {!surcharge.label.toLowerCase().includes('gst') ? (
                    cost
                  ) : (
                    <div className={styles.detailed}>
                      {cost}
                      {/* {props.GST && props.GST.gstCustomerType && (
                              <span className={styles.details}>
                                {props.GST.gstCustomerType[0] + props.GST.gstCustomerType.slice(1).toLowerCase().replace('_', ' ')}
                                {props.GST.gstRegistrationType && `, ${props.GST.gstRegistrationType}`}
                              </span>
                            )} */}
                    </div>
                  )}
                </div>
              );
            })}

            <span className={styles.total}>{props.data.details?.summary.grandTotal.price.displayValue}</span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {backToOrdersButton}
        <div className={styles['footer-buttons']}>
          <Button
            text={getLabel(props.data.labels, 'download')}
            id='download'
            buttonType='ghost'
            onClick={() => onClickFooterBtn('Download order button')}
          />
          <Button
            text={getLabel(props.data.labels, 'reorder_all')}
            id='reorder_all'
            buttonType='primary'
            onClick={() => onClickFooterBtn('Reorder all button')}
          />
        </div>
      </div>
    </div>
  );

  function onClickFooterBtn(eventInfo: string) {
    const attributes: any = { __Type: 'buttonTracker', __Info: eventInfo, __Result: props.orderId.toString() };
    clickEvent(attributes);
  }
};
