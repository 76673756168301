import React, { FunctionComponent } from 'react';
import { OrderSort } from '../../../../../../../generated/graphql';
import { ILabel } from '../../../../../models/common/label.model';
import styles from './order-list-component.module.scss';
import OrderListHeader from './order-list-header/order-list-header-component';
import OrderListRow from './order-list-row/order-list-row-component';

export type OrderListOrder = {
  orderId: number;
  dateOrdered: string;
  orderReference: number;
  orderReferencePersonal?: string;
  totalDisplayValue: string;
  isControlledPurchasing: boolean;
};

export type OrderListProps = {
  labels?: ILabel[];
  orders?: OrderListOrder[];
  // Hides sort arrows and view details
  displayOnly?: boolean;
  showSortArrows?: boolean;
  onSortCallback?: (sortMode: OrderSort) => void;
  sortMode?: OrderSort;
  onClickViewDetails?: (orderId: number) => void;
};

export const OrderList: FunctionComponent<OrderListProps> = (props: OrderListProps) => {
  return (
    <div className={styles.container}>
      <OrderListHeader
        labels={props.labels}
        displayOnly={props.displayOnly}
        onSortCallback={props.onSortCallback}
        className={styles.header}
        sortMode={props.sortMode}
      />
      <div className={styles['row-container']}>
        {props.orders?.map((order) => (
          <OrderListRow
            labels={props.labels}
            orderId={order.orderId}
            dateOrdered={order.dateOrdered}
            orderReference={order.orderReference}
            orderReferencePersonal={order.orderReferencePersonal}
            totalDisplayValue={order.totalDisplayValue}
            showViewDetails={!props.displayOnly}
            isControlledPurchasing={order.isControlledPurchasing}
            onClickViewDetails={props.onClickViewDetails}
          />
        ))}
      </div>
    </div>
  );
};
