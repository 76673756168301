import { Dispatch } from 'react';
import { UserContextAction, UserContextActionType } from '../components/app/user-context';
import {
  BasketTotalDocument,
  BasketTotalQuery,
  BasketTotalQueryVariables,
  HeaderTotalsDocument,
  HeaderTotalsQuery,
  HeaderTotalsQueryVariables,
  PartsListsTotalDocument,
  PartsListsTotalQuery,
  PartsListsTotalQueryVariables,
} from '../generated/graphql';
import { IHeaderService } from './../rs-emd-ui-modules/src/services/header/header-service';
import { graphQLService } from './graphql-service';

export class HeaderService implements IHeaderService {
  // refresh basket totals
  getBasketTotal(userDispatchCallback: Dispatch<UserContextAction> | null) {
    graphQLService.apolloClient
      .query<BasketTotalQuery, BasketTotalQueryVariables>({
        query: BasketTotalDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        if (result && result.data)
          userDispatchCallback?.({
            type: UserContextActionType.UpdateBasketTotal,
            basketTotal: result.data.header.basketTotal,
            noOfItemsInBasket: result.data.header.noOfItemsInBasket,
          });

        (window as any).ReactNativeWebView?.postMessage(result.data.header.noOfItemsInBasket);
      });
  }

  getPartsListsTotal(userDispatchCallback: Dispatch<UserContextAction> | null) {
    graphQLService.apolloClient
      .query<PartsListsTotalQuery, PartsListsTotalQueryVariables>({
        query: PartsListsTotalDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        if (result && result.data)
          userDispatchCallback?.({
            type: UserContextActionType.UpdatePartsListsTotal,
            noOfPartsLists: result.data.header.noOfPartsLists,
          });
      });
  }

  // refresh all header data - customer, basket & partlist totals
  getHeaderTotals(userDispatchCallback: Dispatch<UserContextAction> | null) {
    graphQLService.apolloClient
      .query<HeaderTotalsQuery, HeaderTotalsQueryVariables>({
        query: HeaderTotalsDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        if (result && result.data)
          userDispatchCallback?.({
            type: UserContextActionType.UpdateHeaderTotals,
            basketTotal: result.data.header.basketTotal,
            noOfItemsInBasket: result.data.header.noOfItemsInBasket,
            noOfPartsLists: result.data.header.noOfPartsLists,
            customer: result.data.header.customer,
          });
      });
  }
}

export const headerService = new HeaderService();
